import { useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useFormContext, useController } from 'react-hook-form'

const CheckboxField = ({ labelProps: { label, required }, inputProps, name }) => {
  const id = `id-${name}`

  const { setValue } = useFormContext()
  const { field: { value, ...field }, formState: { defaultValues: { [name]: initialValue } } } = useController({ name })

  const handleOnChange = useCallback(
    e => {
      const status = e.target.checked

      setValue(name, status === !!initialValue ? initialValue : status, { shouldDirty: true })
    },
    [setValue, name, initialValue],
  )

  return (
    <div className="checkbox">
      <label htmlFor={id}>
        <input
          id={id}
          name={name}
          type="checkbox"
          {...field}
          {...inputProps}
          onChange={handleOnChange}
          checked={!!value}
        />
        {label && <span className={clsx({ required })}>{label}</span>}
      </label>
    </div>
  )
}

CheckboxField.defaultProps = {
  inputProps: {},
  labelProps: {},
}

CheckboxField.propTypes = {
  inputProps: PropTypes.shape({}),
  labelProps: PropTypes.shape({
    label: PropTypes.string,
    required: PropTypes.bool,
  }),
  name: PropTypes.string.isRequired,
}

export default CheckboxField
