import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { TERMS_AND_CONDITIONS, PRIVACY_POLICY } from '@/constants'

export const NeedToConfirmPopup = ({ onClose }) => (
  <div className="login-popup_wrapper">
    <div className="login-popup confirm-with-admin">
      <div className="login-popup_header">
        <FormattedMessage id="needToConfirmPopup.header" />
      </div>
      <button type="button" onClick={onClose} className="login-button blue">
        OK
      </button>
    </div>
  </div>
)

NeedToConfirmPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export const TermsAndConditionsPopup = ({ onAccept, onDecline }) => (
  <div className="login-popup_wrapper">
    <div className="login-popup terms-and-conditions">
      <div className="login-popup_header">
        <FormattedMessage id="termsAndConditionsPopup.header" />
      </div>
      <div>
        <FormattedMessage id="termsAndConditionsPopup.message1" />
        <a
          href={TERMS_AND_CONDITIONS}
          target="_blank"
          rel="noopener noreferrer"
          className="login-footer-link"
        >
          <FormattedMessage id="termsAndConditionsPopup.terms" />
        </a>
        <FormattedMessage id="termsAndConditionsPopup.and" />
        <a
          href={PRIVACY_POLICY}
          target="_blank"
          rel="noopener noreferrer"
          className="login-footer-link"
        >
          <FormattedMessage id="termsAndConditionsPopup.privacy" />
        </a>
        <FormattedMessage id="termsAndConditionsPopup.message2" />
      </div>
      <ul className="login-popup_action-container">
        <li>
          <button type="button" onClick={onAccept} className="login-button blue">
            <FormattedMessage id="termsAndConditionsPopup.accept" />
          </button>
          <a className="login-blue-link" onClick={onDecline}>
            <FormattedMessage id="termsAndConditionsPopup.decline" />
          </a>
        </li>
      </ul>
    </div>
  </div>
)

TermsAndConditionsPopup.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
}
