import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { useForm, FormProvider, Form } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { twoFAPasswordRecoverCount } from 'Config'

import { useLoader } from '@/providers/loader/LoaderProvider'
import { useSession } from '@/providers/session/SessionProvider'
import InputField from '@/components/Form/InputField'
import CheckboxField from '@/components/Form/CheckboxField'

import * as LoginApi from '@/api/login'
import * as SecurityApi from '@/api/security'
import { ROOT, NEW_PASSWORD, LOCKED_MEMBER } from '@/routes'
import './TwoFA.scss'

const schema = Yup.object().shape({
  remember_me: Yup.boolean().required(),
  security_code: Yup.string().required(),
})

const TwoFA = () => {
  const { setLoading } = useLoader()
  const { formatMessage } = useIntl()
  const history = useHistory()
  const {
    checkNext,
    clearSession,
    setNextActionPayload,
    tmpToken,
    userEmail,
  } = useSession()
  const submitCount = useRef(0)
  const [wrongCode, setWrongCode] = useState(false)
  const [lockedUser, setLockedUser] = useState(false)

  const handleResend = useCallback(async e => {
    try {
      setLoading(true)
      e.preventDefault()
      if (history.location.state?.pwdReset) {
        await SecurityApi.resetPasswordWith2FA({ email: userEmail })
        return
      }
      await LoginApi.resend2FA(history.location.state.token)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }, [history.location.state?.token, history.location.state?.pwdReset, userEmail, setLoading])

  const phoneNumber = history.location.state?.twoFaPhone || '****'

  const handleSubmit = useCallback(
    async ({ data: values }) => {
      try {
        setWrongCode(false)
        setLoading(true)

        if (history.location.state?.pwdReset) {
          const { data } = await SecurityApi.resetPasswordWith2FAConfirm({
            email: userEmail,
            code: values.security_code,
          })
          setNextActionPayload(data.next_action_payload)
          history.push({ pathname: NEW_PASSWORD, state: { token: data.jwtAuthToken } })
          return
        }
        if (history.location.state.register) {
          await LoginApi.confirm2FAPhone({
            code: values.security_code.toString(),
            remember: values.remember_me,
            auth_token: tmpToken,
            lock: true,
          })

          await checkNext()
        } else {
          await checkNext(values)
        }
      } catch ({ response }) {
        const wrongCodes = [400, 401]

        if (response?.data.is_member_locked) {
          history.push(LOCKED_MEMBER)
          return
        }

        if (history.location.state?.pwdReset) {
          submitCount.current += 1
          if (submitCount.current >= twoFAPasswordRecoverCount) {
            clearSession()
            history.push(ROOT) // TODO show some message
          }
        }

        if (wrongCodes.includes(response.status)) {
          setWrongCode(true)
        } else if (response.status === 429) {
          setLockedUser(true)
        }
      } finally {
        setLoading(false)
      }
    },
    [checkNext, history, setLoading, tmpToken, userEmail, clearSession, setNextActionPayload],
  )

  useEffect(() => {
    setLoading(false)
    if (!history.location.state?.token && !history.location.state?.pwdReset) {
      history.replace(ROOT)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const twoFAForm = useForm({
    defaultValues: { security_code: '', remember_me: false },
    resolver: yupResolver(schema),
  })

  const { isDirty, isValid, isSubmitting } = twoFAForm.formState

  if (lockedUser) {
    return (
      <div className="content-wrapper set-two-fa">
        <div className="login-titles">
          <div className="title">
            <FormattedMessage id="setTwoFa.title" />
          </div>
        </div>
        <div className="login-text-wrapper">
          <FormattedMessage id="setTwoFa.locked" />
        </div>
      </div>
    )
  }

  return (
    <div className="content-wrapper two-fa">
      <div className="login-titles">
        <div className="title">
          <FormattedMessage id="twofa.title" />
        </div>
        <div className="sub-title">
          <FormattedMessage id="twofa.subTitle" />
        </div>
      </div>
      <div className="login-text-wrapper">
        <FormattedMessage id="twofa.verification" values={{ number: phoneNumber }} />
      </div>
      {wrongCode && (
        <div className="wrong-code-warning">
          <FormattedMessage id="twofa.wrongCode" />
        </div>
      )}
      <FormProvider {...twoFAForm}>
        <Form onSubmit={handleSubmit} className="2fa-form-wrapper" autoComplete="off">
          <InputField
            name="security_code"
            inputProps={{
              type: 'text',
              autoFocus: true,
              className: 'security-code',
              placeholder: formatMessage({ id: 'twofa.code' }),
            }}
            errorProps={{ ignoreError: true }}
          />
          { !history.location.state?.pwdReset && (
            <CheckboxField
              name="remember_me"
              labelProps={{ label: formatMessage({ id: 'twofa.rememberDevice' }) }}
            />
          )}
          <div className="login-buttons-wrapper">
            <button
              disabled={!isDirty || !isValid || isSubmitting}
              className={clsx('login-button', { blue: isValid && isDirty && !isSubmitting })}
              type="submit"
            >
              <FormattedMessage id="twofa.verify" />
            </button>
          </div>
          <div className="login-text-wrapper resend">
            <FormattedMessage id="twofa.noCode" />{' '}
            <a href="#" onClick={handleResend} className="login-blue-link">
              <FormattedMessage id="twofa.resend" />
            </a>
          </div>
        </Form>
      </FormProvider>
    </div>
  )
}

export default TwoFA
