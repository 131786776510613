import toast from 'react-hot-toast'
import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'

import { ALERT_TYPES } from '@/constants'

export default ALERT_TYPES.reduce((acc, type) => ({
  ...acc,
  [type]: (message, options) => toast(() => (
    <>
      <b><FormattedMessage id={`alert.${type}`} /></b>
      {message}
    </>
  ), {
    className: clsx('toast-wrapper', type),
    ...options,
  }),
}), {})
