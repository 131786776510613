import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useController } from 'react-hook-form'

const InputField = ({
  errorProps = {},
  inputProps = {},
  labelProps = {},
  name,
}) => {
  const id = `id-${name}`
  const { label, required } = labelProps
  const { message, ignoreError } = errorProps
  const placeholder = inputProps.placeholder || 'enter value'

  const { field, fieldState: { error } } = useController({ name })

  return (
    <>
      <label htmlFor={id}>
        {label && <span className={clsx({ required })}>{label}</span>}
        {!ignoreError && error && <span className="form-error-msg">{message}</span>}
      </label>
      <input id={id} placeholder={placeholder} {...field} {...inputProps} />
    </>
  )
}

InputField.propTypes = {
  inputProps: PropTypes.shape({
    placeholder: PropTypes.string,
  }),
  errorProps: PropTypes.shape({
    message: PropTypes.string,
    ignoreError: PropTypes.bool,
  }),
  labelProps: PropTypes.shape({
    label: PropTypes.string,
    required: PropTypes.bool,
  }),
  name: PropTypes.string.isRequired,
}

export default InputField
