import { useCallback } from 'react'
import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { useForm, FormProvider, Form } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import * as LoginApi from '@/api/login'

import { useLoader } from '@/providers/loader/LoaderProvider'
import { useSession } from '@/providers/session/SessionProvider'
import PhoneNumberField from '@/components/Form/PhoneNumberField'

import { TWO_FA } from '@/routes'

import './TwoFA.scss'

const schema = Yup.object().shape({
  phone: Yup.string().required(),
})

const SetTwoFA = () => {
  const { setLoading } = useLoader()
  const { push } = useHistory()
  const { tmpToken } = useSession()

  const handleSubmit = useCallback(async ({ data }) => {
    try {
      const phoneNumber = data.phone.replace(/[^+|0-9]+/g, '')
      const twoFaPhone = phoneNumber.slice(-4)
      setLoading(true)
      await LoginApi.register2FAPhone({ phone: phoneNumber, auth_token: tmpToken })

      push({ pathname: TWO_FA, state: { token: tmpToken, register: true, twoFaPhone } })
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }, [setLoading, tmpToken, push])

  const setTwoFAForm = useForm({
    defaultValues: { phone: '' },
    resolver: yupResolver(schema),
  })

  const { isDirty, isValid, isSubmitting } = setTwoFAForm.formState

  return (
    <div className="content-wrapper set-two-fa">
      <div className="login-titles">
        <div className="title">
          <FormattedMessage id="setTwoFa.title" />
        </div>
        <div className="sub-title">
          <FormattedMessage id="setTwoFa.subTitle" />
        </div>
      </div>
      <div className="login-text-wrapper">
        <FormattedMessage id="setTwoFa.code" />
      </div>
      <FormProvider {...setTwoFAForm}>
        <Form onSubmit={handleSubmit} className="2fa-form-wrapper">
          <PhoneNumberField name="phone" />
          <div className="login-buttons-wrapper">
            <button
              disabled={!isDirty || !isValid || isSubmitting}
              className={clsx('login-button', { blue: isValid && isDirty && !isSubmitting })}
              type="submit"
            >
              <FormattedMessage id="buttons.finish" />
            </button>
          </div>
        </Form>
      </FormProvider>
    </div>
  )
}

export default SetTwoFA
