import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { useSession } from '@/providers/session/SessionProvider'

import { ROOT } from '@/routes'

const BackToLogin = () => {
  const { clearSession } = useSession()

  return (
    <Link to={ROOT} onClick={clearSession} className="login-back-link">
      <FormattedMessage id="buttons.backToLogin" />
    </Link>
  )
}

export default BackToLogin
