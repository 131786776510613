import { enduranceRequest } from '@/utils/request'
import Config from 'Config'

const wrapParams = data => {
  const params = new URLSearchParams()
  Object.entries(data).forEach(([name, val]) => params.append(name, val))
  return params
}

export const authenticate = data => enduranceRequest({
  method: 'POST',
  url: 'users/authenticate',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  data: wrapParams({
    client_id: Config.client_id,
    client_secret: Config.client_secret,
    scope: Config.client_scope,
    ...data,
  }),
})

export const authenticateWithCode = data => enduranceRequest({
  method: 'POST',
  url: 'users/authenticate',
  data: {
    client_id: Config.client_id,
    client_secret: Config.client_secret,
    scope: Config.client_scope,
    ...data,
  },
})

export const authorize = data => enduranceRequest({
  method: 'POST',
  url: 'users/authorize',
  data,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
})

export const checkNext = data => enduranceRequest({
  method: 'POST',
  url: 'users/check-next',
  data: wrapParams(data),
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
})

export const validateToken = data => enduranceRequest({
  method: 'POST',
  url: 'users/validate',
  data: wrapParams(data),
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
})

export const resend2FA = authToken => enduranceRequest({
  method: 'POST',
  url: '2fa/resend',
  data: { auth_token: authToken },
})

export const register2FAPhone = data => enduranceRequest({
  method: 'POST',
  url: '2fa',
  data,
})

export const confirm2FAPhone = data => enduranceRequest({
  method: 'POST',
  url: '2fa/confirm',
  data,
})
