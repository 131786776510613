import { FormattedMessage } from 'react-intl'

import BackToLogin from '@/components/Login/BackToLogin'

import './LockedMember.scss'

const LockedMember = () => (
  <div className="content-wrapper locked-member">
    <div className="login-titles">
      <div className="sub-title">
        <FormattedMessage id="memberLocked.title" />
      </div>
    </div>
    <div className="login-buttons-wrapper">
      <BackToLogin />
    </div>
  </div>
)

export default LockedMember
