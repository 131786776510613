export const RECOVER_PASSWORD = '/recover-password'
export const RESET_PASSWORD = '/reset-password'
export const EXPIRED_LINK = '/expired-link'
export const NEW_PASSWORD = '/new-password'
export const NEW_PASSWORD_RECOVERY = '/new-password/:code([0-9a-fA-F]{64})'
export const EMAIL_SENT = '/email-sent'
export const PASSWORD_RESET_SUCCESS = '/password-reset-success'
export const PASSWORD = '/password'
export const TWO_FA = '/2fa'
export const SET_2FA = '/set-2fa'
export const UPDATE_EMAIL = '/update-email'
export const LOCKED_MEMBER = '/locked-member'
export const LOCKED_USER = '/locked-user'

export const ROOT = '/'
