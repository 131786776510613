import {
  NEW_PASSWORD,
  SET_2FA,
  TWO_FA,
  UPDATE_EMAIL,
} from '@/routes'

export const AUTH_CONTEXT = 'create'

export const CREATE_ACCOUNT_URL = 'https://get.zenqms.com/sourceapplogin/learnmoredemo'

export const TERMS_AND_CONDITIONS = 'https://www.zenqms.com/app_tos'

export const PRIVACY_POLICY = 'https://www.zenqms.com/app_privacy_policy'

export const STATUS = 'https://status.zenqms.com/'

export const PASSWORD_VALIDATIONS = [
  'isLengthValid',
  'containsNumber',
  'hasNoRepeatCharacters',
  'notUsed',
  'notEmail',
  'isMatch',
]

// eslint-disable-next-line max-len
export const EMAIL_REGEX = /^(?!^[.])[a-zA-Z0-9.!#$%&'*+/=?^_{|}~`"-]*[a-zA-Z0-9-"]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/

export const UPDATE_PASSWORD_ACTION = 'update_password'
export const SET_2FA_ACTION = 'set_2fa'
export const UPDATE_EMAIL_ACTION = 'update_email'

export const NEXT_ACTION_PATHNAMES = {
  [UPDATE_PASSWORD_ACTION]: [NEW_PASSWORD],
  [SET_2FA_ACTION]: [SET_2FA, TWO_FA],
  [UPDATE_EMAIL_ACTION]: [UPDATE_EMAIL],
}

export const ALERT_TYPES = ['success', 'error', 'warning']
export const SUPPORTED_BROWSERS = ['Chrome', 'Firefox', 'Safari', 'Microsoft Edge']
export const SUPPORTED_BROWSER_VERSIONS = {
  mobile: {
    chrome: '>=87.0.4280.77',
    safari: '>=14.0',
    firefox: '>=85.0',
    edge: '>=46.1.2',
  },
  chrome: '>=88.0.4324.146',
  firefox: '>=85.0',
  safari: '>=14.0.2',
  edge: '>=88.0.705.56',
}
