import ReactDOM from 'react-dom'
import { isIE } from 'react-device-detect'
import axios from 'axios'
import '@/utils/rollbar'

import App from '@/components/App'

if (isIE) {
  axios.interceptors.request.use(
    config => ({ ...config, headers: { ...config.headers, Pragma: 'no-cache', 'Cache-Control': 'no-cache' } }),
    error => Promise.reject(error),
  )
}

const locale = (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language || 'en-US'

const rootEl = document.getElementById('login-app')

ReactDOM.render(<App locale={locale} />, rootEl)
