import { useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { useSession } from '@/providers/session/SessionProvider'

import { ROOT } from '@/routes'

const UserRoute = props => {
  const { userEmail } = useSession()
  const { replace } = useHistory()

  useEffect(() => {
    if (!userEmail) replace(ROOT)
  }, [userEmail]) // eslint-disable-line react-hooks/exhaustive-deps

  return <Route {...props} />
}

export default UserRoute
