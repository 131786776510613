/* eslint-disable max-len */
module.exports = {
  buttons: {
    backToLogin: 'Zurück zum Login',
    change: 'Ändern',
    finish: 'Abschließen',
    next: 'Weiter',
    ok: 'OK',
    submit: 'Senden',
  },
  emailSent: {
    checkEmail: 'Wir haben eine E-Mail mit Anweisungen zum Zurücksetzen an diese Adresse gesendet. Wenn Sie sie nicht gleich sehen, überprüfen Sie bitte Ihren Spam-Ordner oder stellen Sie sicher, dass die oben angegebene E-Mail-Adresse korrekt ist.',
    resentLink: 'Link erneut senden',
    subTitle: 'Prüfen Sie Ihren Posteingang',
  },
  expiredLink: {
    sentNewLink: 'Es sieht so aus, als ob dieser Link abgelaufen ist. Wir haben einen neuen Link an Ihre E-Mail-Adresse gesendet.',
    title: 'Huch!',
  },
  lockedUser: {
    title: 'Ihr Kontostatus ist entweder „Deaktiviert“, „Ausgesperrt“ oder „Nicht eingeladen“. Bitte wenden Sie sich an Ihren ZenQMS-Administrator, wenn Sie Hilfe beim Zugriff auf Ihr Konto benötigen.',
  },
  loginMain: {
    checkID: 'Die eingegebene Benutzer-ID/das eingegebene Kennwort ist falsch',
    forgotEmail: 'Haben sie Ihre E-Mail oder ID vergessen?',
    forgotPassword: 'Haben Sie Ihr Passwort vergessen?',
    invalidUsername: 'Der von Ihnen eingegebene Benutzername ist nicht gültig.',
    login: 'Anmelden',
    noAccount: 'Sie haben noch kein Konto?',
  },
  needToConfirmPopup: {
    header: 'Bitte wenden Sie sich an den ZenQMS-Administrator Ihres Unternehmens, wenn Sie Hilfe bei der Verifizierung Ihrer Konto-ID benötigen.',
  },
  newPassword: {
    containsNumber: '- mindestens 1 Nummer enthalten',
    create: 'Ein neues Passwort erstellen',
    expiredPasswordAlert: 'Ihr Passwort ist abgelaufen und muss zurückgesetzt werden.',
    hasNoRepeatCharacters: '- nicht mehr als 3 sich wiederholende Zeichen enthalten',
    isLengthValid: '- mindestens {minPasswordLength} Zeichen enthalten',
    isMatch: '- Passwörter stimmen überein',
    notEmail: '- nicht mit Ihrer E-Mail identisch sein',
    notUsed: '- Keine Wiederholung eines der letzten 12 Passwörter',
    passwordMust: 'Ihr Passwort muss:',
    submit: 'Senden',
  },
  passwordResetSuccess: {
    subTitle: 'Klicken Sie unten auf „Zurück zur Anmeldung“, um sich mit Ihrem neuen Passwort anzumelden.',
    title: 'Ihr Passwort wurde zurückgesetzt!',
  },
  placeholders: {
    emailOrId: 'E-Mail oder Benutzer-ID',
    newPassword: 'Neues Passwort',
    password: 'Passwort',
    passwordConfirm: 'Neues Passwort bestätigen',
  },
  recoverPassword: {
    sendCode: 'Code an mein Gerät senden',
    sendLink: 'Link an meine E-Mail senden',
    subTitle: 'Wie möchten Sie Ihr Passwort wiederherstellen?',
    title: 'Passwort-Wiederherstellung',
  },
  resetPassword: {
    email: 'E-Mail',
    sendLink: 'Link senden',
    subTitle: 'Verifizierung erforderlich',
  },
  setTwoFa: {
    code: 'Wir senden Ihnen einen Sicherheitscode an diese Nummer, wenn Sie sich von einem nicht vertrauenswürdigen Gerät aus anmelden.',
    subTitle: 'Geben Sie Ihre Mobilfunknummer ein',
    title: 'Einrichtung der Zwei-Faktoren-Autorisierung',
    locked: 'Das ist nicht korrekt. Ihr Konto wurde als Sicherheitsmaßnahme gesperrt.  Bitten Sie Ihren Administrator, Ihr Konto zurückzusetzen oder senden Sie eine E-Mail an help@zenqms.com.',
  },
  termsAndConditionsPopup: {
    accept: 'Akzeptieren',
    and: ' und ',
    decline: 'Ablehnen',
    header: 'Vertragsbedingungen/Datenschutzrichtlinie',
    message1: 'Ich habe die aktuellen ZenQMS-Richtlinien gelesen und erkläre mich damit einverstanden, diese einzuhalten ',
    message2: ', einschließlich der Zustimmung zu temporären Sitzungs-Cookies, die für die Datenverarbeitung und die Speicherung von Informationen auf unseren sicheren Servern in der ganzen Welt erforderlich sind.',
    privacy: 'Datenschutzrichtlinie',
    terms: 'Nutzungsbedingungen',
  },
  twofa: {
    code: 'Sicherheitscode',
    noCode: 'Sie haben keinen Code erhalten?',
    rememberDevice: 'Dieses Gerät für 30 Tage merken',
    resend: 'Erneut senden',
    subTitle: 'Ihr Konto ist mit Zwei-Faktor-Authentifizierung (2FA) geschützt',
    title: 'Sicherheitsüberprüfung',
    verification: 'Eine Textnachricht mit Ihrem Code wurde an (***) ***-{number}gesendet.',
    verify: 'Verifizieren',
    wrongCode: 'Der eingegebene Code ist falsch, bitte versuchen Sie es erneut',
    verificationLimits: 'Sie haben das Verifizierungslimit überschritten, bitte versuchen Sie es in 10 Minuten erneut',
  },
  updateEmail: {
    alreadyExist: 'Diese E-Mail-Adresse wird bereits verwendet',
    code: 'Wir senden Ihnen einen Sicherheitscode an diese Nummer, wenn Sie sich von einem nicht vertrauenswürdigen Gerät aus anmelden.',
    title: 'Ihre E-Mail wurde durch Ihre administrativen Einstellungen eingeschränkt. Bitte geben Sie eine neue E-Mail-Adresse für Ihr Konto ein.',
    success: 'Ihre E-Mail wurde aktualisiert. Bitte klicken Sie auf „Zurück zur Anmeldung“, um sich in die Anwendung einzuloggen.',
    wrongDomain: 'diese E-Mail-Domäne ist nicht erlaubt',
  },
  alert: {
    error: 'Fehler',
    success: 'Erfolg',
    warning: 'Warnung',
  },
  memberLocked: {
    title: 'Das Konto Ihrer Firma wurde gesperrt. Bitte wenden Sie sich an Ihren ZenQMS-Administrator oder an billing@zenqms.com.',
  },
  validations: {
    email: 'E-Mail ist ein Pflichtfeld',
  },
}
