import enMessages from './languages/en-US'
import esMessages from './languages/es-ES'
import zhMessages from './languages/zh-CN'
import frMessages from './languages/fr-FR'
import itMessages from './languages/it-IT'
import ptMessages from './languages/pt-BR'
import deMessages from './languages/de-DE'
import jaMessages from './languages/ja-JP'
import frCAMessages from './languages/fr-CA'
import ruMessages from './languages/ru-RU'

const messages = {
  'en-US': enMessages,
  'es-ES': esMessages,
  'fr-FR': frMessages,
  'fr-CA': frCAMessages,
  'it-IT': itMessages,
  'zh-CN': zhMessages,
  'pt-BR': ptMessages,
  'de-DE': deMessages,
  'ja-JP': jaMessages,
  'ru-RU': ruMessages,
}

export default locale => {
  const currentLocaleFullName = Object.keys(messages).find(m => m === locale)
  const currentLocalePrefix = Object.keys(messages).find(m => m.indexOf(locale.slice(0, 2)) === 0)

  return messages[currentLocaleFullName || currentLocalePrefix || 'en-US']
}
