import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { jwtDecode } from 'jwt-decode'

import { urls } from 'Config'
import * as LoginApi from '@/api/login'
import * as SsoApi from '@/api/sso'
import searchQueryParse from '@/utils/searchQueryParse'

export const SessionContext = createContext()

export const useSession = () => useContext(SessionContext)

// WARN: Disabling hooks dependencies check for optimization, be careful when changing or adding some methods
/* eslint-disable react-hooks/exhaustive-deps */

const SessionProvider = ({ children }) => {
  const tmpTokenRef = useRef(null)
  const [tmpToken, setTmpToken] = useState(null)
  const [uiTab, setUiTab] = useState(null)
  const [action, setNextAction] = useState(undefined)
  const [nextActionPayload, setNextActionPayload] = useState({})
  const [token, setToken] = useState(null)
  const [userEmail, setUserEmail] = useState(null)

  const setAuthToken = useCallback(tkn => {
    setTmpToken(tkn)
    tmpTokenRef.current = tkn
  }, [])

  const authenticate = useCallback(async ({ username, password }) => {
    const { data } = await LoginApi.authenticate({ username, password })
    const { nextAction } = jwtDecode(data.jwtAuthToken)

    setAuthToken(data.jwtAuthToken)
    setUserEmail(username)

    if (data.require_2fa) return data

    setNextActionPayload(data.next_action_payload)
    setNextAction(nextAction)
    return data
  }, [])

  const validateToken = useCallback(async params => {
    const { data } = await LoginApi.validateToken(params)
    setToken(data.token)
  }, [])

  const checkNext = useCallback(async (params = {}) => {
    const { data } = await LoginApi.checkNext({ ...params, auth_token: tmpTokenRef.current })
    setAuthToken(data.jwtAuthToken)
    const { nextAction } = jwtDecode(data.jwtAuthToken)
    setNextActionPayload(data.next_action_payload)
    setNextAction(nextAction)

    if (!nextAction) await validateToken({ auth_token: data.jwtAuthToken })
  }, [])

  const authenticateWithCode = useCallback(async code => {
    const { data } = await LoginApi.authenticateWithCode({ recovery_code: code })

    setNextActionPayload(data.next_action_payload)
    setAuthToken(data.jwtAuthToken)
    return data
  }, [])

  const checkProvider = useCallback(async ({ username }) => {
    if (username.includes('+')) return undefined
    const domain = username.replace(/.*@/, '')

    const { data: { data } } = await SsoApi.checkProvider(domain)

    if (data) return domain
    return undefined
  }, [])

  const clearSession = useCallback(() => {
    setAuthToken(null)
    setNextActionPayload({})
    setNextAction(undefined)
    setUserEmail(null)
  }, [])

  useEffect(() => {
    if (token) {
      const zendeskPath = '/zendesk'

      if (uiTab && uiTab.startsWith(zendeskPath)) {
        const query = searchQueryParse(uiTab.replace(zendeskPath, ''))
        window.location.assign(`${urls.enduranceApi}zendesk${query.path ? `?return_to=${query.path}` : ''}`)
      } else {
        window.location.assign(`/dashboard.aspx${uiTab ? `#${uiTab}` : ''}`)
      }
    }
  }, [token, uiTab])

  const value = useMemo(() => ({
    authenticate,
    authenticateWithCode,
    checkNext,
    checkProvider,
    clearSession,
    nextAction: action,
    nextActionPayload,
    setNextActionPayload,
    setUiTab,
    setUserEmail,
    tmpToken,
    uiTab,
    userEmail,
    validateToken,
  }), [userEmail, tmpToken, action, nextActionPayload, uiTab, validateToken, checkNext])

  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
}

SessionProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SessionProvider
