import { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'

import { useLoader } from '@/providers/loader/LoaderProvider'
import { useSession } from '@/providers/session/SessionProvider'
import BackToLogin from '@/components/Login/BackToLogin'

import * as SecurityApi from '@/api/security'

import './EmailSent.scss'

const EmailSent = () => {
  const { setLoading } = useLoader()
  const { userEmail } = useSession()

  const handleEmailReset = useCallback(async () => {
    try {
      setLoading(true)
      await SecurityApi.resetPassword(userEmail)
    } finally {
      setLoading(false)
    }
  }, [setLoading, userEmail])

  return (
    <div className="content-wrapper email-sent">
      <div className="envelope" />
      <div className="login-titles">
        <div className="sub-title">
          <FormattedMessage id="emailSent.subTitle" />
        </div>
      </div>
      <div className="login-text-wrapper">
        <p>{userEmail}</p>
      </div>
      <div className="login-text-wrapper">
        <FormattedMessage id="emailSent.checkEmail" />
      </div>
      <div className="login-buttons-wrapper">
        <button className="login-button blue" type="button" onClick={handleEmailReset}>
          <FormattedMessage id="emailSent.resentLink" />
        </button>
        <BackToLogin />
      </div>
    </div>
  )
}

export default EmailSent
