/* eslint-disable max-len */
module.exports = {
  buttons: {
    backToLogin: 'ログインに戻る',
    change: '変更する',
    finish: '終了する',
    next: '次へ',
    ok: 'OK',
    submit: '送信する',
  },
  emailSent: {
    checkEmail: 'このアドレスにリセット方法のメールを送りました。1分経っても表示されない場合は、迷惑メールフォルダを確認するか、上記のメールアドレスが正しいことを確認してください。',
    resentLink: 'リンクを再送する',
    subTitle: '受信箱を確認する',
  },
  expiredLink: {
    sentNewLink: 'そのリンクの有効期限が切れたようです。あなたのメールアドレスに新しいメール再送しました。',
    title: 'おっと!',
  },
  lockedUser: {
    title: 'アカウントの状態は、「無効」、「ロックアウト」、「招待なし」のいずれかです。アカウントへのアクセスについては、ZenQMS管理者にお問い合わせください。',
  },
  loginMain: {
    checkID: '入力したユーザーID/パスワードが正しくありません。',
    forgotEmail: 'EメールやIDをお忘れですか？',
    forgotPassword: 'パスワードをお忘れですか？',
    invalidUsername: '入力されたユーザー名は有効ではありません。',
    login: 'ログインする',
    noAccount: 'アカウントをお持ちではありませんか？',
  },
  needToConfirmPopup: {
    header: 'アカウントIDの確認については、貴社のZenQMS管理者にお問い合わせください。',
  },
  newPassword: {
    containsNumber: '- 数字を1文字以上含めること',
    create: '新しいパスワードを作成する',
    expiredPasswordAlert: 'パスワードの有効期限が切れているため、再設定が必要です。',
    hasNoRepeatCharacters: '- 同じ文字を3回以上使わないこと',
    isLengthValid: '- {minPasswordLength}文字以上にすること',
    isMatch: '- パスワードの一致',
    notEmail: '- メールと同じにならないこと',
    notUsed: '- 過去12回のパスワードを繰り返さない',
    passwordMust: 'パスワードは以下が必須：',
    submit: '送信する',
  },
  passwordResetSuccess: {
    subTitle: '下の「ログインに戻る」をクリックして、新しいパスワードでサインインしてください。',
    title: 'パスワードがリセットされました！',
  },
  placeholders: {
    emailOrId: 'EメールまたはユーザーID',
    newPassword: '新しいパスワード',
    password: 'パスワード',
    passwordConfirm: '新しいパスワードを確認する',
  },
  recoverPassword: {
    sendCode: 'マイデバイスにコードを送信する',
    sendLink: 'リンクを私のメールに送る',
    subTitle: 'パスワードの回復方法は？',
    title: 'パスワードの回復方',
  },
  resetPassword: {
    email: 'Eメール',
    sendLink: 'リンクを送信する',
    subTitle: '確認が必要です',
  },
  setTwoFa: {
    code: '信頼できないデバイスからサインインする際には、必ずこの番号にセキュリティコードを送信します。',
    subTitle: '携帯電話の番号を入力する',
    title: '2要素認証の設定',
    locked: 'それは正しくありません。アカウントはセキュリティ対策のためにロックアウトされています。管理者にアカウントの再設定を依頼するか、help@zenqms.com にメールしてください。',
  },
  termsAndConditionsPopup: {
    accept: '承認',
    and: ' または ',
    decline: '拒否',
    header: '利用規約/プライバシーポリシー',
    message1: '私は最新のZenQMSを確認し、',
    message2: 'データ処理に必要な一時的なセッション・クッキーへの同意や、世界各地にある当社の安全なサーバーへの情報の保存を含め、遵守することに同意します。',
    privacy: 'プライバシーポリシー',
    terms: '利用規約',
  },
  twofa: {
    code: 'セキュリティーコード',
    noCode: 'コードが届いていませんか？',
    rememberDevice: 'このデバイスを30日間記憶させておく',
    resend: '再送する',
    subTitle: 'アカウントは2要素認証（2FA）で保護されています。',
    title: 'セキュリティの確認',
    verification: 'あなたのコードが記載されたテキストメッセージが（***）***に送信されました。-{number}',
    verify: '確認する',
    wrongCode: '入力されたコードが正しくありません。もう一度お試しください。',
    verificationLimits: '認証の上限を超えています。10分後にもう一度お試しください。',
  },
  updateEmail: {
    alreadyExist: 'このメールはすでに使用されています',
    code: '疑わしいデバイスからサインインする際には、必ずこの番号にセキュリティコードを送信します。',
    title: 'あなたのメールは、管理者の設定によって制限されています。アカウントの新しいメールアドレスを入力してください。',
    success: 'あなたのメールが更新されました。「ログインに戻る」をクリックして、アプリケーションにログインしてください。',
    wrongDomain: 'このメールのドメインは許可されていません。',
  },
  alert: {
    error: 'エラー',
    success: '成功',
    warning: '警告',
  },
  memberLocked: {
    title: 'あなたの会社のアカウントがロックされました。ZenQMS管理者またはbilling@zenqms.comにお問い合わせください',
  },
  validations: {
    email: 'Eメールは必須項目です',
  },
}
