import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { useLoader } from '@/providers/loader/LoaderProvider'
import { useSession } from '@/providers/session/SessionProvider'
import Footer from '@/components/Login/Footer'
import Spinner from '@/components/Spinner/Spinner'

import { ROOT } from '@/routes'
import toast from '@/utils/toast'
import { NEXT_ACTION_PATHNAMES, UPDATE_PASSWORD_ACTION } from '@/constants'
import { environment } from 'Config'

import './Layout.scss'
import './LoginLogo.scss'
import './LoginContent.scss'

const env = (() => {
  switch (true) {
    case environment.startsWith('test'):
      return 'test'
    case environment.startsWith('sandbox'):
      return 'sandbox'
    case environment.startsWith('production'):
      return 'production'
    default:
      return 'dev'
  }
})()

const Layout = ({ children }) => {
  const { formatMessage } = useIntl()
  const { push } = useHistory()
  const { pathname } = useLocation()
  const { loading, setLoading } = useLoader()
  const { nextAction, nextActionPayload } = useSession()

  useEffect(() => {
    if ((NEXT_ACTION_PATHNAMES[nextAction] && !NEXT_ACTION_PATHNAMES[nextAction].includes(pathname))) {
      if (nextAction === UPDATE_PASSWORD_ACTION && nextActionPayload.is_password_expired) {
        toast.warning(formatMessage({ id: 'newPassword.expiredPasswordAlert' }))
      }
      push(NEXT_ACTION_PATHNAMES[nextAction][0])
      setLoading(false)
    }
  }, [nextAction, pathname, push, nextActionPayload.is_password_expired, formatMessage, setLoading])

  return (
    <div className="login-page">
      <div className="login-side">
        <div className="logo-wrapper">
          <Link to={ROOT} />
        </div>
        {environment !== 'production'
          ? <div className="environment"> Environment: <mark>{environment}</mark> </div>
          : null
        }
        {children}
        <Footer />
        {loading && <Spinner />}
      </div>
      <div className="login-iframe">
        <iframe className="responsive-iframe" src={`https://www.zenqms.com/customer-resources/${env}`}></iframe>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
