/* eslint-disable max-len */
module.exports = {
  buttons: {
    backToLogin: 'Voltar ao login',
    change: 'Alteração',
    finish: 'Concluir',
    next: 'Próximo',
    ok: 'OK (OK)',
    submit: 'Enviar',
  },
  emailSent: {
    checkEmail: 'Enviamos um e-mail para este endereço com instruções de redefinição. Se você não o vir em um minuto, verifique sua pasta de spam ou verifique se o endereço de e-mail acima está correto.',
    resentLink: 'Enviar link novamente',
    subTitle: 'Verifique sua caixa de entrada',
  },
  expiredLink: {
    sentNewLink: 'Parece que o link expirou. Enviamos um novo para o seu endereço de e-mail.',
    title: 'Opa!',
  },
  lockedUser: {
    title: 'O status da sua conta é Desativado, Bloqueado ou Não Convidado. Entre em contato com o administrador do ZenQMS para obter ajuda para acessar sua conta.',
  },
  loginMain: {
    checkID: 'A ID de usuário/senha inserida está incorreta',
    forgotEmail: 'Não se lembra do e-mail ou ID?',
    forgotPassword: 'Esqueceu sua senha?',
    invalidUsername: 'O nome de usuário inserido não é válido.',
    login: 'Fazer login',
    noAccount: 'Você não tem uma conta?',
  },
  needToConfirmPopup: {
    header: 'Entre em contato com o administrador ZenQMS da sua empresa para obter ajuda para verificar o ID da sua conta.',
  },
  newPassword: {
    containsNumber: '- Ter pelo menos 1 número',
    create: 'Criar uma nova senha',
    expiredPasswordAlert: 'Sua senha expirou e deve ser redefinida.',
    hasNoRepeatCharacters: '- Não incluir mais de 3 caracteres repetidos',
    isLengthValid: '- Ter pelo menos {minPasswordLength} caracteres',
    isMatch: '- As senhas correspondem',
    notEmail: '- Não ser o mesmo que seu e-mail',
    notUsed: '- Não repetir uma das suas 12 últimas senhas',
    passwordMust: 'Sua senha deve:',
    submit: 'Enviar',
  },
  passwordResetSuccess: {
    subTitle: 'Clique em “Voltar ao login” abaixo para entrar com sua nova senha.',
    title: 'Sua senha foi redefinida!',
  },
  placeholders: {
    emailOrId: 'ID de usuário ou e-mail',
    newPassword: 'Nova senha',
    password: 'Senha',
    passwordConfirm: 'Confirmar nova senha',
  },
  recoverPassword: {
    sendCode: 'Enviar código para meu dispositivo',
    sendLink: 'Enviar link para meu e-mail',
    subTitle: 'Como você gostaria de recuperar sua senha?',
    title: 'Recuperação de senha',
  },
  resetPassword: {
    email: 'E-mail',
    sendLink: 'Enviar link',
    subTitle: 'Verificação necessária',
  },
  setTwoFa: {
    code: 'Enviaremos um código de segurança para este número sempre que você acessar a partir de um dispositivo não confiável.',
    subTitle: 'Digite seu número de celular',
    title: 'Configuração de autorização de dois fatores',
    locked: 'Isso não está correto. Sua conta foi bloqueada como precaução de segurança.  Peça ao administrador para redefinir sua conta ou envie um e-mail para help@zenqms.com.',
  },
  termsAndConditionsPopup: {
    accept: 'Aceitar',
    and: ' e ',
    decline: 'Recusar',
    header: 'Termos e Condições/Política de Privacidade',
    message1: 'Eu revisei e concordo em cumprir o mais recente ZenQMS ',
    message2: 'incluindo o consentimento para cookies de sessão temporários necessários para o processamento de dados e armazenamento de informações em nossos servidores seguros em todo o mundo.',
    privacy: 'Política de privacidade',
    terms: 'Termos de serviço',
  },
  twofa: {
    code: 'Código de segurança',
    noCode: 'Não recebeu um código?',
    rememberDevice: 'Lembrar este dispositivo por 30 dias',
    resend: 'Reenviar',
    subTitle: 'Sua conta está protegida com autenticação de dois fatores (2FA)',
    title: 'Verificação de segurança',
    verification: 'Uma mensagem de texto com seu código foi enviada para (***) ***-{number}',
    verify: 'Verificar',
    wrongCode: 'O código digitado está incorreto, tente novamente',
    verificationLimits: 'Você excedeu o limite de verificação, tente novamente em 10 minutos',
  },
  updateEmail: {
    alreadyExist: 'Este e-mail já está sendo usado',
    code: 'Enviaremos um código de segurança para este número sempre que você acessar a partir de um dispositivo não confiável.',
    title: 'Seu e-mail foi restringido por suas configurações de administração. Digite um novo endereço de e-mail para sua conta.',
    success: 'Seu e-mail foi atualizado. Clique em “Voltar ao login” para fazer login no aplicativo.',
    wrongDomain: 'este domínio de e-mail não é permitido',
  },
  alert: {
    error: 'Erro',
    success: 'Sucesso',
    warning: 'Aviso',
  },
  memberLocked: {
    title: 'A conta da sua empresa foi bloqueada. Entre em contato com o administrador do ZenQMS ou envie um e-mail para billing@zenqms.com',
  },
  validations: {
    email: 'E-mail é um campo obrigatório',
  },
}
