/* eslint-disable max-len */
module.exports = {
  buttons: {
    backToLogin: 'Torna all\'accesso',
    change: 'Modifica',
    finish: 'Termina',
    next: 'Successivo',
    ok: 'OK',
    submit: 'Invia',
  },
  emailSent: {
    checkEmail: 'Abbiamo inviato un\'e-mail a quest’indirizzo con le istruzioni per il ripristino. Se non lo vedi tra un minuto, controlla la cartella spam o verifica che l’indirizzo e-mail sopra indicato sia corretto.',
    resentLink: 'Invia di nuovo collegamento',
    subTitle: 'Controlla la tua casella di posta in arrivo',
  },
  expiredLink: {
    sentNewLink: 'Sembra che il link sia scaduto. Ne abbiamo inviato uno nuovo al tuo indirizzo e-mail.',
    title: 'Ops!',
  },
  lockedUser: {
    title: 'Lo stato del tuo account è Disabilitato, Bloccato o Non invitato. Contattare l’amministratore ZenQMS per assistenza nell’accesso al proprio account.',
  },
  loginMain: {
    checkID: 'L’ID utente/la password inseriti non sono corretti',
    forgotEmail: 'Non ricordi l’e-mail o l’ID?',
    forgotPassword: 'Hai dimenticato la password?',
    invalidUsername: 'Il nome utente inserito non è valido.',
    login: 'Accedi',
    noAccount: 'Non hai un account?',
  },
  needToConfirmPopup: {
    header: 'Contattare l\'amministratore ZenQMS dell\'azienda per assistenza nella verifica dell\'ID account.',
  },
  newPassword: {
    containsNumber: '- Avere almeno 1 numero',
    create: 'Crea una nuova password',
    expiredPasswordAlert: 'La password è scaduta e deve essere reimpostata.',
    hasNoRepeatCharacters: '- Non includere più di 3 caratteri ripetuti',
    isLengthValid: '- Avere almeno {minPasswordLength} caratteri',
    isMatch: '- Corrispondenza password',
    notEmail: '- Non essere uguale alla tua e-mail',
    notUsed: '- Non ripetere una delle ultime 12 password',
    passwordMust: 'La password deve:',
    submit: 'Invia',
  },
  passwordResetSuccess: {
    subTitle: 'Fai clic su “Torna all’accesso” qui sotto per accedere con la tua nuova password.',
    title: 'La password è stata reimpostata!',
  },
  placeholders: {
    emailOrId: 'E-mail o ID utente',
    newPassword: 'Nuova password',
    password: 'Password',
    passwordConfirm: 'Conferma nuova password',
  },
  recoverPassword: {
    sendCode: 'Invia codice al mio dispositivo',
    sendLink: 'Invia link alla mia e-mail',
    subTitle: 'Come si desidera recuperare la password?',
    title: 'Recupero password',
  },
  resetPassword: {
    email: 'Indirizzo e-mail',
    sendLink: 'Invia collegamento',
    subTitle: 'Verifica richiesta',
  },
  setTwoFa: {
    code: 'Ti invieremo un codice di sicurezza a questo numero ogni volta che accedi da un dispositivo non attendibile.',
    subTitle: 'Inserisci il tuo numero di cellulare',
    title: 'Configurazione autorizzazione a due fattori',
    locked: 'Risposta sbagliata. Il tuo account è stato bloccato come precauzione di sicurezza.  Chiedere all\'amministratore di reimpostare l\'account o inviare un\'e-mail a help@zenqms.com.',
  },
  termsAndConditionsPopup: {
    accept: 'Accetta',
    and: ' e ',
    decline: 'Rifiuta',
    header: 'Termini e condizioni/Informativa sulla privacy',
    message1: 'Ho esaminato e accettato di rispettare l’ultima versione di ZenQMS ',
    message2: ', incluso il consenso ai cookie di sessione temporanei richiesti per il trattamento dei dati e l\'archiviazione delle informazioni nei nostri server sicuri in tutto il mondo.',
    privacy: 'Informativa sulla privacy',
    terms: 'Termini di servizio',
  },
  twofa: {
    code: 'Codice di sicurezza',
    noCode: 'Non hai ricevuto un codice?',
    rememberDevice: 'Ricordi questo dispositivo per 30 giorni',
    resend: 'Invia di nuovo',
    subTitle: 'Il tuo account è protetto con l\'autenticazione a due fattori (2FA)',
    title: 'Verifica della sicurezza',
    verification: 'Un messaggio di testo con il codice è stato inviato a (***) ***-{number}',
    verify: 'Verifica',
    wrongCode: 'Il codice inserito non è corretto, riprova',
    verificationLimits: 'Hai superato il limite di verifica, riprova tra 10 minuti',
  },
  updateEmail: {
    alreadyExist: 'Quest’e-mail è già in uso',
    code: 'Ti invieremo un codice di sicurezza a questo numero ogni volta che accedi da un dispositivo non attendibile.',
    title: 'Il tuo indirizzo e-mail è stato limitato dalle impostazioni di amministrazione. Inserisci un nuovo indirizzo e-mail per il tuo account.',
    success: 'La tua e-mail è stata aggiornata. Fare clic su “Torna all’accesso” per accedere all’applicazione.',
    wrongDomain: 'questo dominio e-mail non è \'consentito',
  },
  alert: {
    error: 'Errore',
    success: 'Successo',
    warning: 'Avvertenza',
  },
  memberLocked: {
    title: 'L\'account della tua azienda è stato bloccato. Contattare l’amministratore ZenQMS o billing@zenqms.com',
  },
  validations: {
    email: 'E-mail è un campo obbligatorio',
  },
}
