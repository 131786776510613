import { useCallback, useState, useEffect } from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { useForm, FormProvider, Form } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormattedMessage, useIntl } from 'react-intl'
import * as Yup from 'yup'

import { useLoader } from '@/providers/loader/LoaderProvider'
import { useSession } from '@/providers/session/SessionProvider'
import InputField from '@/components/Form/InputField'
import { NeedToConfirmPopup } from '@/components/Login/LoginPopups'

import { CREATE_ACCOUNT_URL, EMAIL_REGEX } from '@/constants'
import { urls } from 'Config'

import { PASSWORD, NEW_PASSWORD } from '@/routes'
import './Login.scss'

const schema = Yup.object().shape({
  username: Yup.string().matches(EMAIL_REGEX).required(),
})

const Login = () => {
  const { setLoading } = useLoader()
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { checkProvider, setUserEmail, uiTab } = useSession()
  const [error, setError] = useState(false)
  const [popup, setPopup] = useState(false)

  useEffect(() => history.listen((location, action) => {
    if (action === 'POP' && (location.pathname === NEW_PASSWORD)) {
      history.push('/')
    }
  }, [history]))

  const handleSubmit = useCallback(
    async ({ data }) => {
      try {
        setLoading(true)
        const ssoDomain = await checkProvider(data)
        if (ssoDomain) {
          window.location.assign(`${urls.ssoApi}${ssoDomain}/${uiTab
            ? `?${encodeURIComponent(`returnurl=/dashboard.aspx#${uiTab}`)}`
            : ''
          }`)
          return
        }
        setUserEmail(data.username)
        history.push({ pathname: PASSWORD })
      } catch {
        setError(true)
      } finally {
        setLoading(false)
      }
    },
    [checkProvider, history, setLoading, setUserEmail, uiTab],
  )

  const loginForm = useForm({
    defaultValues: { username: '' },
    resolver: yupResolver(schema),
  })

  const { isDirty, isSubmitting } = loginForm.formState

  return (
    <div className="content-wrapper main-login">
      <FormProvider {...loginForm}>
        <Form onSubmit={handleSubmit} className="login-form-wrapper" autoComplete="off">
          <ul className="login-field-wrapper">
            {error && (
              <li>
                <ul className="error-section">
                  <li className="incorrect">{<FormattedMessage id="loginMain.checkID" />}</li>
                </ul>
              </li>
            )}
            <li>
              <InputField
                name="username"
                inputProps={{
                  autoComplete: 'off',
                  autoFocus: true,
                  placeholder: formatMessage({ id: 'placeholders.emailOrId' }),
                  maxLength: 100,
                }}
                errorProps={{ message: formatMessage({ id: 'loginMain.invalidUsername' }) }}
              />
            </li>
            <li className="actions">
              <a onClick={() => setPopup(true)} className="login-blue-link">
                {<FormattedMessage id="loginMain.forgotEmail" />}
              </a>
              <button
                disabled={!isDirty || isSubmitting}
                type="submit"
                className={clsx('login-button', { blue: isDirty && !isSubmitting })}
              >
                {<FormattedMessage id="buttons.next" />}
              </button>
            </li>
          </ul>
        </Form>
        <div className="no-account">
          <a
            href={CREATE_ACCOUNT_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatMessage({ id: 'loginMain.noAccount' })}
          </a>
        </div>
      </FormProvider>
      {popup && <NeedToConfirmPopup onClose={() => setPopup(false)} />}
    </div>
  )
}

export default Login
