import { useCallback, useState } from 'react'
import clsx from 'clsx'
import { FormattedMessage, useIntl } from 'react-intl'
import { useForm, FormProvider, Form } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import { useLoader } from '@/providers/loader/LoaderProvider'
import { useSession } from '@/providers/session/SessionProvider'
import InputField from '@/components/Form/InputField'
import BackToLogin from '@/components/Login/BackToLogin'

import toast from '@/utils/toast'
import * as SecurityApi from '@/api/security'

import './UpdateEmail.scss'

const schema = Yup.object().shape({
  email: Yup.string().required({ id: 'validations.email' }),
})

const UpdateEmail = () => {
  const { formatMessage } = useIntl()
  const { setLoading } = useLoader()
  const { tmpToken } = useSession()

  const [updateStatus, setUpdateStatus] = useState(false)

  const handleSubmit = useCallback(async ({ data }) => {
    try {
      setLoading(true)
      await SecurityApi.updateEmail({ ...data, auth_token: tmpToken })
      setUpdateStatus(true)
    } catch (err) {
      if (err.response?.data?.validations?.isEmailAvailable === false) {
        toast.error(formatMessage({ id: 'updateEmail.alreadyExist' }))
      }
      if (err.response.data.name === 'ValidationError' || err.response?.data?.validations?.isDomainValid === false) {
        toast.error(formatMessage({ id: 'updateEmail.wrongDomain' }))
      }
    } finally {
      setLoading(false)
    }
  }, [setLoading, tmpToken, formatMessage])

  const updateEmailForm = useForm({
    defaultValues: { email: '' },
    resolver: yupResolver(schema),
  })

  const { isDirty, isValid, isSubmitting } = updateEmailForm.formState

  return (
    <div className="content-wrapper update-email">
      {updateStatus ? (
        <div className="title">
          <FormattedMessage id="updateEmail.success" />
        </div>
      ) : (
        <>
          <div className="title">
            <FormattedMessage id="updateEmail.title" />
          </div>
          <FormProvider {...updateEmailForm}>
            <Form onSubmit={handleSubmit} className="login-form-wrapper" autoComplete="off">
              <ul className="login-field-wrapper">
                <li>
                  <InputField
                    name="email"
                    inputProps={{
                      autoComplete: 'off',
                      autoFocus: true,
                      placeholder: formatMessage({ id: 'placeholders.emailOrId' }),
                    }}
                  />
                </li>
                <li className="actions">
                  <button
                    disabled={!isDirty || !isValid || isSubmitting}
                    type="submit"
                    className={clsx('login-button', { blue: isValid && isDirty && !isSubmitting })}
                  >
                    {<FormattedMessage id="buttons.submit" />}
                  </button>
                </li>
              </ul>
            </Form>
          </FormProvider>
        </>
      )}
      <div className="login-buttons-wrapper">
        <BackToLogin />
      </div>
    </div>
  )
}

export default UpdateEmail
