/* eslint-disable max-len */
module.exports = {
  buttons: {
    backToLogin: 'Retour à Ouverture de session',
    change: 'Modifier',
    finish: 'Terminer',
    next: 'Suivant',
    ok: 'OK',
    submit: 'Soumettre',
  },
  emailSent: {
    checkEmail: 'Nous avons envoyé un courriel à cette adresse comprenant des instructions de réinitialisation. Si vous ne le voyez pas dans une minute, vérifiez votre dossier de pourriels ou assurez-vous que l’adresse courriel ci-dessus est exacte.',
    resentLink: 'Renvoyer le lien',
    subTitle: 'Vérifiez votre boîte de réception',
  },
  expiredLink: {
    sentNewLink: 'Il semble que ce lien ait expiré. Nous en avons envoyé un nouveau à votre adresse courriel.',
    title: 'Oups!',
  },
  lockedUser: {
    title: 'Le statut de votre compte est soit « Désactivé », « Verrouillé » ou « Non invité ». Veuillez communiquer avec votre administrateur ZenQMS pour obtenir de l’aide pour accéder à votre compte.',
  },
  loginMain: {
    checkID: 'Le nom d’utilisateur/mot de passe que vous avez saisi est incorrect',
    forgotEmail: 'Vous ne vous souvenez pas du courriel ou de l’identifiant?',
    forgotPassword: 'Vous avez oublié votre mot de passe?',
    invalidUsername: 'Le nom d\'utilisateur que vous avez saisi n\'est pas valide.',
    login: 'Ouvrir une session',
    noAccount: 'Vous n’avez pas de compte?',
  },
  needToConfirmPopup: {
    header: 'Veuillez communiquer avec l’administrateur ZenQMS de votre entreprise pour obtenir de l’aide pour vérifier votre identifiant de compte.',
  },
  newPassword: {
    containsNumber: 'Saisir au moins 1 chiffre',
    create: 'Créer un nouveau mot de passe',
    expiredPasswordAlert: 'Votre mot de passe a expiré et doit être réinitialisé.',
    hasNoRepeatCharacters: ' – Ne doit pas contenir plus de 3 caractères identiques consécutifs',
    isLengthValid: ' – Doit comprendre au moins {minPasswordLength} caractères',
    isMatch: ' – Les mots de passe correspondent',
    notEmail: ' – Ne doit pas être identique au courriel',
    notUsed: ' – Ne doit pas être l’un des 12 derniers mots de passe',
    passwordMust: 'Votre mot de passe doit :',
    submit: 'Soumettre',
  },
  passwordResetSuccess: {
    subTitle: 'Cliquez sur « Retour à l’ouverture de session » ci-dessous pour vous connecter avec votre nouveau mot de passe.',
    title: 'Votre mot de passe a été réinitialisé!',
  },
  placeholders: {
    emailOrId: 'Courriel ou identifiant d’utilisateur',
    newPassword: 'Nouveau mot de passe',
    password: 'Mot de passe',
    passwordConfirm: 'Confirmer le nouveau mot de passe',
  },
  recoverPassword: {
    sendCode: 'Envoyer le code à mon appareil',
    sendLink: 'Envoyer le lien vers mon courriel',
    subTitle: 'Comment souhaitez-vous récupérer votre mot de passe?',
    title: 'Récupération du mot de passe',
  },
  resetPassword: {
    email: 'Courriel',
    sendLink: 'Envoyer le lien',
    subTitle: 'Vérification requise',
  },
  setTwoFa: {
    code: 'Nous vous enverrons un code de sécurité à ce numéro chaque fois que vous vous connecterez à partir d’un appareil non sécurisé.',
    subTitle: 'Saisissez votre numéro de téléphone cellulaire',
    title: 'Configuration de l’autorisation à deux facteurs',
    locked: 'Ce n’est pas la bonne réponse. Votre compte a été verrouillé par mesure de sécurité.  Veuillez demander à votre administrateur de réinitialiser votre compte ou envoyez un courriel à help@zenqms.com.',
  },
  termsAndConditionsPopup: {
    accept: 'Accepter',
    and: ' et ',
    decline: 'Refuser',
    header: 'Modalités/politique de confidentialité',
    message1: 'J’ai examiné et j’accepte de respecter les plus récentes politiques de ZenQMS ',
    message2: ', y compris le consentement aux témoins temporaires requis pour le traitement des données et le stockage des informations dans nos serveurs sécurisés partout dans le monde.',
    privacy: 'Politique de confidentialité',
    terms: 'Modalités de service',
  },
  twofa: {
    code: 'Code de sécurité',
    noCode: 'Vous n’avez pas reçu de code?',
    rememberDevice: 'Mémoriser cet appareil pendant 30 jours',
    resend: 'Renvoyer',
    subTitle: 'Votre compte est protégé par l’authentification à deux facteurs (2FA)',
    title: 'Vérification de sécurité',
    verification: 'Un message texte contenant votre code a été envoyé à (***) ***-{number}',
    verify: 'Vérifier',
    wrongCode: 'Le code que vous avez saisi est incorrect, veuillez réessayer',
    verificationLimits: 'Vous avez dépassé la limite de vérification, veuillez réessayer dans 10 minutes',
  },
  updateEmail: {
    alreadyExist: 'Cette adresse courriel est déjà utilisée',
    code: 'Nous vous enverrons un code de sécurité à ce numéro chaque fois que vous vous connecterez à partir d’un appareil non sécurisé.',
    title: 'Vos paramètres d’administration ont limité l’accès de votre courriel. Veuillez saisir une nouvelle adresse courriel pour votre compte.',
    success: 'Votre courriel a été mis à jour. Veuillez cliquer sur « Retour à la connexion » pour vous connecter à l’application.',
    wrongDomain: 'ce domaine de courriel n’est \'pas autorisé',
  },
  alert: {
    error: 'Erreur',
    success: 'Succès',
    warning: 'Avertissement',
  },
  memberLocked: {
    title: 'Le compte de votre entreprise a été verrouillé. Veuillez communiquer avec votre administrateur ZenQMS ou billing@zenqms.com',
  },
  validations: {
    email: 'Le champ Courriel est obligatoire',
  },
}
