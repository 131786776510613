import axios from 'axios'
import { urls } from 'Config'

export const defaultRequest = async params => {
  const defaults = {
    method: 'GET',
  }

  return axios({ ...defaults, ...params })
}

export const enduranceRequest = params => defaultRequest({
  baseURL: urls.enduranceApi,
  ...params,
})

export const ssoRequest = params => defaultRequest({
  baseURL: urls.ssoApi,
  ...params,
})
