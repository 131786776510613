/* eslint-disable max-len */
module.exports = {
  buttons: {
    backToLogin: 'Back to Login',
    change: 'Change',
    finish: 'Finish',
    next: 'Next',
    ok: 'OK',
    submit: 'Submit',
  },
  emailSent: {
    checkEmail: 'We sent an email to this address with reset instructions. If you don’t see it in a minute, please check your spam folder or verify the email address above is correct.',
    resentLink: 'Resend Link',
    subTitle: 'Check Your Inbox',
  },
  expiredLink: {
    sentNewLink: 'It looks like that link expired. We have sent a new one to your email address.',
    title: 'Oops!',
  },
  lockedUser: {
    title: 'Your account status is either Disabled, Locked Out or Uninvited. Please contact your ZenQMS administrator for help accessing your account.',
  },
  loginMain: {
    checkID: 'The UserID/password you entered is incorrect',
    forgotEmail: 'Don’t remember email or User ID?',
    forgotPassword: 'Forgot your Password?',
    invalidUsername: 'The username you entered is not valid.',
    login: 'Log in',
    noAccount: 'Don’t have an account?',
  },
  needToConfirmPopup: {
    header: 'Please contact your company ZenQMS administrator for help verifying your account ID.',
  },
  newPassword: {
    containsNumber: '- Have at least 1 number',
    create: 'Create a New Password',
    expiredPasswordAlert: 'Your password has expired and must be reset.',
    hasNoRepeatCharacters: '- Not include more than 3 repeating characters',
    isLengthValid: '- Have at least {minPasswordLength} characters',
    isMatch: '- Passwords match',
    notEmail: '- Not be the same as your email',
    notUsed: '- Not repeat one of your last 12 passwords',
    passwordMust: 'Your Password must:',
    submit: 'Submit',
  },
  passwordResetSuccess: {
    subTitle: 'Click ‘Back to Login’ below to sign in with your new password.',
    title: 'Your password has been reset!',
  },
  placeholders: {
    emailOrId: 'Email or User ID',
    newPassword: 'New Password',
    password: 'Password',
    passwordConfirm: 'Confirm New Password',
  },
  recoverPassword: {
    sendCode: 'Send Code to My Device',
    sendLink: 'Send Link to my Email',
    subTitle: 'How would you like to recover your password?',
    title: 'Password Recovery',
  },
  resetPassword: {
    email: 'Email',
    sendLink: 'Send Link',
    subTitle: 'Verification Required',
  },
  setTwoFa: {
    code: 'We will send you a security code to this number whenever you sign in from a non-trusted device.',
    subTitle: 'Enter your mobile phone number',
    title: 'Two-Factor Authorization Setup',
    locked: 'That is not correct. Your account has been locked out as a security precaution. Please ask your administrator to reset your account or email help@zenqms.com.',
  },
  termsAndConditionsPopup: {
    accept: 'Accept',
    and: ' and ',
    decline: 'Decline',
    header: 'Terms and Conditions/ Privacy Policy',
    message1: 'I have reviewed and agree to abide by the latest ZenQMS ',
    message2: ', including consent to temporary session cookies required for data processing and storage of information in our secure servers around the world.',
    privacy: 'Privacy Policy',
    terms: 'Terms of Service',
  },
  twofa: {
    code: 'Security Code',
    noCode: 'Didn’t Receive a Code?',
    rememberDevice: 'Remember this Device for 30 days',
    resend: 'Resend',
    subTitle: 'Your account is protected with Two-Factor Authentication (2FA)',
    title: 'Security Verification',
    verification: 'A text message with your code has been sent to (***) ***-{number}',
    verify: 'Verify',
    wrongCode: 'The code you entered is incorrect, please try again',
    verificationLimits: 'You have exceeded the verification limit, please try again in 10 minutes',
  },
  updateEmail: {
    alreadyExist: 'This email is already being used',
    code: 'We will send you a security code to this number whenever you sign in from a non-trusted device.',
    title: 'Your email has been restricted by your administration settings. Please enter a new email address for your account.',
    success: 'Your email has been updated. Please click “Back to Login” to login to the application.',
    wrongDomain: 'this email domain isn\'t allowed',
  },
  alert: {
    error: 'Error',
    success: 'Success',
    warning: 'Warning',
  },
  memberLocked: {
    title: 'Your company’s account has been locked. Please contact your ZenQMS administrator or billing@zenqms.com',
  },
  validations: {
    email: 'Email is a required field',
  },
}
