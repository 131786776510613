import { version } from 'Config'

import { TERMS_AND_CONDITIONS, PRIVACY_POLICY, STATUS } from '@/constants'

import './Footer.scss'

const Footer = () => (
  <div className="footer-wrapper">
    <footer>
      <div className="button-container">
        <a href="mailto:help@zenqms.com?subject=ZenQMS Homepage Help Request">
          <button type="button">Support</button>
        </a>
      </div>
      <ul className="footer-info">
        <li>
          <span className="highlight">© ZenQMS LLC</span>
        </li>
        <li className="footer-separator">|</li>
        <li>
          <span>
            <a
              href={TERMS_AND_CONDITIONS}
              target="_blank"
              rel="noopener noreferrer"
              className="login-footer-link"
            >
              Terms
            </a>
            ,{' '}
            <a
              href={PRIVACY_POLICY}
              target="_blank"
              rel="noopener noreferrer"
              className="login-footer-link"
            >
              Privacy
            </a>
            , &amp;{' '}
            <a
              href={STATUS}
              target="_blank"
              rel="noopener noreferrer"
              className="login-footer-link"
            >
              Status
            </a>
          </span>
        </li>
        <li className="footer-separator">|</li>
        <li>
          <span>{version}</span>
        </li>
      </ul>
    </footer>
  </div>
)

export default Footer
