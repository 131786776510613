import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useSession } from '@/providers/session/SessionProvider'
import { ROOT } from '@/routes'

import './RecoverPassword.scss'

const tabs = [
  /^\/qsheet-[A-Z]{3}[0-9]{6}/,
  /^\/edit_issue-[0-9]{1,}/,
  /^\/edit_document-[0-9]{1,}/,
  /^\/pdf_view-([0-9]{1,})-?([0-9]{1,})?-?([0-9]{1,})?/,
  /^\/change-control-[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/,
  /^\/issue-[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/,
  /^\/task-[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/,
  /^\/zendesk/,
]

const NoMatch = () => {
  const { replace } = useHistory()
  const { pathname, search } = useLocation()
  const { setUiTab } = useSession()

  useEffect(() => {
    if (tabs.some(t => t.test(pathname))) {
      const path = search ? pathname + search : pathname

      setUiTab(path)
    }
    replace(ROOT)
  }, [replace, pathname, search, setUiTab])

  return null
}

export default NoMatch
