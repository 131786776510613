import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import BackToLogin from '@/components/Login/BackToLogin'

import { NEW_PASSWORD } from '@/routes'

import './PasswordResetSuccess.scss'

const PasswordResetSuccess = () => {
  const history = useHistory()

  useEffect(() => history.listen((location, action) => {
    if (action === 'POP' && location.pathname === NEW_PASSWORD) history.push('/')
  }, [history]))

  return (
    <div className="content-wrapper password-reset-success">
      <div className="login-titles">
        <div className="title">
          <FormattedMessage id="passwordResetSuccess.title" />
        </div>
        <div className="sub-title">
          <FormattedMessage id="passwordResetSuccess.subTitle" />
        </div>
      </div>
      <div className="login-buttons-wrapper">
        <BackToLogin />
      </div>
    </div>
  )
}

export default PasswordResetSuccess
