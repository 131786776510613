import { enduranceRequest } from '@/utils/request'
import Config from 'Config'

export const resetPassword = email => enduranceRequest({
  method: 'POST',
  url: 'security/password-reset',
  data: {
    email,
  },
})

export const resetPasswordWith2FA = data => enduranceRequest({
  method: 'POST',
  url: 'security/password-reset-2fa',
  data,
})

export const resetPasswordWith2FAConfirm = data => enduranceRequest({
  method: 'POST',
  url: 'security/password-reset-2fa-confirm',
  data: {
    client_id: Config.client_id,
    client_secret: Config.client_secret,
    scope: Config.client_scope,
    ...data,
  },
})

export const setPassword = data => enduranceRequest({
  method: 'POST',
  url: 'security/set-password',
  data,
})

export const validatePassword = data => enduranceRequest({
  method: 'POST',
  url: 'security/validate-password',
  data,
})

export const updateEmail = data => enduranceRequest({
  method: 'PATCH',
  url: 'security/update-email',
  data,
})
