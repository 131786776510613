import { FormattedMessage } from 'react-intl'

import BackToLogin from '@/components/Login/BackToLogin'

import './LockedUser.scss'

const LockedUser = () => (
  <div className="content-wrapper locked-user">
    <div className="login-titles">
      <div className="sub-title">
        <FormattedMessage id="lockedUser.title" />
      </div>
    </div>
    <div className="login-buttons-wrapper">
      <BackToLogin />
    </div>
  </div>
)

export default LockedUser
