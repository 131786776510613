import {
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react'
import PropTypes from 'prop-types'

export const LoaderContext = createContext({ loading: false })

export const useLoader = () => useContext(LoaderContext)

const LoaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)

  const value = useMemo(() => ({ loading, setLoading }), [loading, setLoading])

  return <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>
}

LoaderProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LoaderProvider
