import { Switch, Route } from 'react-router-dom'

import EmailSent from '@/components/pages/EmailSent'
import Login from '@/components/pages/Login'
import NewPassword from '@/components/pages/NewPassword'
import NoMatch from '@/components/pages/NoMatch'
import PasswordResetSuccess from '@/components/pages/PasswordResetSuccess'
import RecoverPassword from '@/components/pages/RecoverPassword'
import ResetPassword from '@/components/pages/ResetPassword'
import Password from '@/components/pages/Password'
import ExpiredLink from '@/components/pages/ExpiredLink'
import TwoFA from '@/components/pages/TwoFA'
import SetTwoFA from '@/components/pages/SetTwoFA'
import UpdateEmail from '@/components/pages/UpdateEmail'
import LockedMember from '@/components/pages/LockedMember'
import LockedUser from '@/components/pages/LockedUser'
import UserRoute from '@/components/routes/UserRoute'

import {
  ROOT,
  EMAIL_SENT,
  EXPIRED_LINK,
  LOCKED_MEMBER,
  LOCKED_USER,
  NEW_PASSWORD_RECOVERY,
  NEW_PASSWORD,
  PASSWORD_RESET_SUCCESS,
  PASSWORD,
  RECOVER_PASSWORD,
  RESET_PASSWORD,
  SET_2FA,
  TWO_FA,
  UPDATE_EMAIL,
} from '@/routes'

const Router = () => (
  <Switch>
    <Route exact path={ROOT} component={Login} />
    <UserRoute path={EMAIL_SENT} component={EmailSent} />
    <Route exact path={NEW_PASSWORD} component={NewPassword} />
    <Route path={NEW_PASSWORD_RECOVERY} component={NewPassword} />
    <UserRoute exact path={PASSWORD} component={Password} />
    <Route path={PASSWORD_RESET_SUCCESS} component={PasswordResetSuccess} />
    <UserRoute path={RECOVER_PASSWORD} component={RecoverPassword} />
    <UserRoute path={RESET_PASSWORD} component={ResetPassword} />
    <Route path={EXPIRED_LINK} component={ExpiredLink} />
    <UserRoute path={TWO_FA} component={TwoFA} />
    <UserRoute path={SET_2FA} component={SetTwoFA} />
    <UserRoute path={UPDATE_EMAIL} component={UpdateEmail} />
    <Route path={LOCKED_MEMBER} component={LockedMember} />
    <Route path={LOCKED_USER} component={LockedUser} />
    <Route path="*" component={NoMatch} />
  </Switch>
)

export default Router
