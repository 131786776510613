import Rollbar from 'rollbar'
import Config from 'Config'

const CURRENT_ENVIRONMENT = process.env.ROLLBAR_ENV || 'localhost'
const ROLLBAR_ENABLED = CURRENT_ENVIRONMENT !== 'localhost'

const config = {
  enabled: ROLLBAR_ENABLED,
  accessToken: Config.rollbarToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  hostSafeList: ['zenqms.com'],
  payload: {
    environment: CURRENT_ENVIRONMENT,
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true,
        code_version: Config.version,
      },
    },
  },
}

// not using for now because of GDPR (need some investigation)
export const addRollbarUser = person => {
  window.rollbar.configure({ payload: { person } })
}

export default Rollbar.init(config)
