import { useCallback, useRef, useState } from 'react'
import clsx from 'clsx'
import { useHistory, Link } from 'react-router-dom'
import { useForm, FormProvider, Form } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormattedMessage, useIntl } from 'react-intl'
import * as Yup from 'yup'
import { passwordRecoverCount } from 'Config'

import { useLoader } from '@/providers/loader/LoaderProvider'
import { useSession } from '@/providers/session/SessionProvider'
import useAuthenticate from '@/hooks/useAuthenticate'
import PasswordField from '@/components/Form/PasswordField'

import {
  ROOT,
  LOCKED_MEMBER,
  RECOVER_PASSWORD,
  RESET_PASSWORD,
} from '@/routes'
import './Login.scss'

const schema = Yup.object().shape({
  password: Yup.string().required(),
})

const Password = () => {
  const { setLoading } = useLoader()
  const { formatMessage } = useIntl()
  const { push } = useHistory()
  const { userEmail } = useSession()
  const submitCount = useRef(0)
  const [error, setError] = useState(false)
  const authenticate = useAuthenticate()

  const handleSubmit = useCallback(
    async ({ data }) => {
      try {
        setLoading(true)
        await authenticate(data)
        submitCount.current = 0
      } catch (err) {
        if (err.response?.data.is_member_locked) {
          push(LOCKED_MEMBER)
          return
        }
        setError(true)
        submitCount.current += 1
        if (submitCount.current >= passwordRecoverCount) push(RECOVER_PASSWORD)
      } finally {
        setLoading(false)
      }
    },
    [authenticate, push, setLoading],
  )

  const passwordForm = useForm({
    defaultValues: { username: userEmail, password: '' },
    resolver: yupResolver(schema),
  })

  const { isDirty, isValid, isSubmitting } = passwordForm.formState

  return (
    <div className="content-wrapper main-login">
      <FormProvider {...passwordForm}>
        <Form onSubmit={handleSubmit} className="login-form-wrapper" autoComplete="off">
          <ul className="login-field-wrapper">
            {error && (
              <li>
                <ul className="error-section">
                  <li className="incorrect">{<FormattedMessage id="loginMain.checkID" />}</li>
                </ul>
              </li>
            )}
            <li className="username-config">
              <div>{userEmail}</div>
              <Link to={ROOT} className="login-blue-link">
                {<FormattedMessage id="buttons.change" />}
              </Link>
            </li>
            <li>
              <PasswordField
                name="password"
                inputProps={{ placeholder: formatMessage({ id: 'placeholders.password' }), autoFocus: true }}
              />
            </li>
            <li className="actions password">
              <Link
                to={{ pathname: RESET_PASSWORD }}
                className="login-blue-link"
              >
                {<FormattedMessage id="loginMain.forgotPassword" />}
              </Link>
              <button
                disabled={!isDirty || !isValid || isSubmitting}
                type="submit"
                className={clsx('login-button', { blue: isValid && isDirty && !isSubmitting })}
              >
                {<FormattedMessage id="buttons.next" />}
              </button>
            </li>
          </ul>
        </Form>
      </FormProvider>
    </div>
  )
}

export default Password
