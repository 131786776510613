export const removeQuestionFirst = (query = '') => {
  if (query.charAt(0) === '?') return query.slice(1)

  return query
}

export default (query = '') => {
  if (!query) return {}
  const searchQuery = removeQuestionFirst(query)
  const queryArray = searchQuery.split('&')

  return queryArray.reduce((queryObj, currentQuery = '') => {
    if (!currentQuery || typeof currentQuery !== 'string') {
      return queryObj
    }

    const [key, value] = currentQuery.split('=')

    return {
      ...queryObj,
      [key]: value,
    }
  }, {})
}
