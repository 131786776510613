const flattenMessages = (nestedMessages, prefix = '') => Object
  .entries(nestedMessages)
  .reduce((messages, [key, value]) => {
    const prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') return { ...messages, [prefixedKey]: value }
    return { ...messages, ...flattenMessages(value, prefixedKey) }
  }, {})

export default flattenMessages
