import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useController } from 'react-hook-form'

const maskCode = '•'

const PasswordField = ({
  errorProps = {},
  inputProps = {},
  labelProps = {},
  name,
}) => {
  const id = `id-${name}`
  const { label, required } = labelProps
  const { message, ignoreError } = errorProps
  const placeholder = inputProps.placeholder || 'enter value'

  const inputRef = useRef(null)
  const range = useRef(0)
  const cursor = useRef(0)
  const [displayValue, setDisplayValue] = useState('')

  const { field: { value, onChange, ...field }, fieldState: { invalid } } = useController({ name })

  const handleInputChange = useCallback(
    ({ target: { value: newValue, selectionStart } }) => {
      cursor.current = selectionStart

      const isIncrease = newValue.length > value.length
      const insertLength = newValue.length - value.length + range.current
      const newChange = newValue.substr(selectionStart - insertLength, insertLength)

      const valueArray = Array.from(value)

      if (isIncrease || (range.current && insertLength > 0)) {
        valueArray.splice(selectionStart - insertLength, range.current, ...Array.from(newChange))
      } else {
        valueArray.splice(selectionStart, Math.abs(insertLength) + range.current)
      }

      onChange(valueArray.join('') || '')
      setDisplayValue(maskCode.repeat(valueArray.length))
    },
    [value, onChange],
  )

  const handleEvent = useCallback(({ target: { selectionStart, selectionEnd } }) => {
    range.current = selectionEnd - selectionStart
  }, [])

  useEffect(() => {
    inputRef.current.selectionStart = cursor.current
    inputRef.current.selectionEnd = cursor.current
  }, [value])

  return (
    <>
      {label && (
        <label htmlFor={id}>
          <span className={clsx({ required })}>{label}</span>
          {!ignoreError && invalid && <span className="form-error-msg">{message}</span>}
        </label>
      )}
      <input
        id={id}
        type="text"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        placeholder={placeholder}
        {...field}
        {...inputProps}
        ref={inputRef}
        value={displayValue}
        onChange={handleInputChange}
        onKeyDown={handleEvent}
        onCut={handleEvent}
        onPaste={handleEvent}
      />
    </>
  )
}

PasswordField.propTypes = {
  inputProps: PropTypes.shape({
    placeholder: PropTypes.string,
  }),
  errorProps: PropTypes.shape({
    message: PropTypes.string,
    ignoreError: PropTypes.bool,
  }),
  labelProps: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    required: PropTypes.bool,
  }),
  name: PropTypes.string.isRequired,
}

export default PasswordField
